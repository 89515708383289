
const CONTENTFUL = {
  header: '2cK583ApYVDDTYMItvYxKu', // Enrollment Initial Header
  bank_account_not_saved_modal: 'B3isxCN7NJCwHpTW3fST4', // Invalid Address Modal Info
  bank_info_modal: 's78DDXBxBqyRHL1i5sFXW', // Illegal Characters Modal Info
  cancel_modal: '2Zf88aDALwIQyQnnSylRR5', // Cancel Modal Info
  confirm_address_modal: '2bOrqF0NGNLS4nG7xGpeua', // Confirm Your Address Modal Info
  illegal_characters_modal: '6b5zkIv7zgXwuNV8cNLHYm', // Illegal Characters Modal Info
  invalid_address_modal: 'B3isxCN7NJCwHpTW3fST4', // Invalid Address Modal Info
  index: '6tHYx99h1507T8yEldykUH', // Enrollment Initial Page
  loader_screen: 'mrnnxbDjGIuUaCKHxq4fw', // Enrollment - Create Account Loader Screen
  commissions_setup: '23DYNC5cm6Vw1Bb2c4rWXZ', // Enrollment - Commissions Setup
  confirmation: '6DKYsZQnvgmjJ0IHWchd7D', // Enrollment Confirmation Page
  create_account: '5gdlncGHJ7P33x3ScyED3q', // Enrollment - Create Account Step
  pay: '4MH6P6dlWZzTkuJj832YX3', // Enrollment Payment Page
  review: '1N6ZFvn2mV9NSEz6KNj3n5', // Enrollment - Review Page
  select_kit: '4e5WRydIbx9DuCvgjAushR', // Enrollment Starter Kit - Select Kit Page
  select_product: '1AAZzl7NLTCIJo7oEMikHd', // Enrollment Starter Kit - Select Product Page
  korea_terms: '1or2h9PC78g1xWORQPr9dV',
  marketing_optin_terms: '4WMUWK4DvINgUt5CSQvyID',
}

export default CONTENTFUL