export default function replaceLabelPlaceholders(label, formData) {
  const matches = label.match(/{{\w+}}/g); // Regex to find all occurrences of placeholders like {{something}}

  if (matches) {
    matches.forEach((match) => {
      const key = match.replace(/[{}]/g, '').toUpperCase(); // Remove the curly braces to get the key

      if (formData[key]) {
        label = label.replace(match, formData[key]); // Replace the placeholder with the data from the form
      } else {
        label = label.replace(' to ' + match, '');
      }
    });
  }
  return label;
}
